<template>
    <div class="login-bind-account flex-align">
        <div class="board">
            <ul class="header flex-align">
                <li>
                    <a href="/" rel="noopener noreferrer">
                        <!-- <img src="@/assets/images/home/logo-three.png" alt /> -->
                        <img src="@/assets/images/home/doubleLogo.png" alt />
                    </a>
                </li>
                <!-- <li>
          <a href target="_blank" rel="noopener noreferrer">
            <img src="@/assets/images/home/unclogo.png" alt />
          </a>
        </li> -->
                <!-- <li>
          <a href target="_blank" rel="noopener noreferrer"
            >银川市双优云桥智慧研修学院</a
          >
        </li> -->
            </ul>
            <!-- <div class="container"> -->
            <h2 class="title">关联智慧研修平台账号</h2>
            <p class="tips">关联后，您可以用银川智慧教育平台账号登录研修平台</p>
            <div class="form-section">
                <el-form v-if="!activeForm" key="ruleForm" :model="ruleForm" status-icon ref="ruleForm"
                    label-width="70px" class="login-form">
                    <el-form-item label="用户名：" prop="username">
                        <el-input type="text" v-model="ruleForm.username" maxlength="11" autocomplete="off"
                            placeholder="请输入手机号码或用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="密码：" prop="password">
                        <el-input type="password" v-model="ruleForm.password" autocomplete="off" placeholder="请输入登录密码">
                        </el-input>
                    </el-form-item>

                    <el-form-item label prop="type" class="summary">
                        <el-link type="primary" class="forget" @click="open()">忘记密码？
                        </el-link>
                    </el-form-item>
                    <el-form-item class="btn-box">
                        <el-button type="primary" @click="submitForm(ruleForm)" class="login-btn">关联</el-button>
                    </el-form-item>
                    <el-form-item>
                        <p @click="activeForm = 1" class="csp switch">手机验证码登录</p>
                    </el-form-item>
                </el-form>

                <el-form v-else key="verifyForm" :rules="rule" :model="verifyForm" status-icon ref="verifyForm"
                    label-width="70px" class="login-form" hide-required-asterisk>
                    <el-form-item label="手机号：" prop="phone">
                        <el-input type="text" v-model="verifyForm.phone" maxlength="11" autocomplete="off"
                            placeholder="请输入手机号码">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="验证码：" prop="verifyCode" class="el-form-code-item">
                        <el-input v-model="verifyForm.verifyCode" placeholder="请输入手机验证码"></el-input>
                        <el-button class="getVerfiyCode" @click="getCode" v-if="showCode">获取验证码</el-button>
                        <el-button class="getVerfiyCode" v-else>{{`重新发送(${count}s)`}}</el-button>
                    </el-form-item>

                    <el-form-item class="btn-box">
                        <el-button type="primary" @click="submitVerifyForm('verifyForm')" class="login-btn">
                            关联
                        </el-button>
                    </el-form-item>

                    <el-form-item>
                        <p @click="activeForm = 0" class="csp switch">账号密码登录</p>
                    </el-form-item>
                </el-form>


                <!-- </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data() {
            var checkPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('手机号不能为空'));
                } else if (!/^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/.test(
                        value)) {
                    return callback(new Error('手机号格式错误'));
                } else {
                    return callback();
                }
            };
            var checkCode = (rule, value, callback) => {
                // console.log(value)
                if (!value) {
                    return callback(new Error('验证码不能为空'));
                } else {
                    let params = {
                        phone: this.verifyForm.phone,
                        code: String(value)
                    }
                    this.$Api.Login.checkCode(params).then((resData) => {
                        // console.log(resData)
                        if (resData.data.result) {
                            return callback();
                        } else {
                            this.$message({
                                message: resData.data.msg,
                                type: 'error'
                            });
                            return callback(new Error('验证码错误'));
                        }
                    })
                }
            };
            return {
                ruleForm: {
                    password: "",
                    username: "",
                    type: []
                },
                verifyForm: {
                    phone: "",
                    verifyCode: "",
                },
                dialogVisible: false,
                activeForm: 0,
                rule: {
                    phone: [{
                        required: true,
                        trigger: "blur",
                        validator: checkPhone
                    }],
                    verifyCode: [{
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                        validator: checkCode
                    }],
                },
                showCode: true,
                count: 0,
                timer: "",
                flag: false,
                checkBindInfo: {
                    account: "",
                    uniqueid: "",
                }
            };
        },
        methods: {
            // 获取验证码
            async getCode() {
                if (!/^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/.test(this
                        .verifyForm
                        .phone)) {
                    this.$message({
                        message: '手机号格式错误',
                        type: 'error'
                    });
                    return
                }
                this.countDown();
                let params = {
                    phone: this.verifyForm.phone
                }
                let resData = await this.$Api.Login.getVerifyCode(params)
                // console.log(resData);
                if (resData.code === 200 && resData.msg === "请求成功") {
                    this.$message({
                        message: '验证码发送成功',
                        type: 'success'
                    });
                } else {
                    this.$message({
                        message: resData.msg,
                        type: 'error'
                    });
                }
            },
            // 倒计时
            countDown() {
                const TIME_COUNT = 60;
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.showCode = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count--;
                        } else {
                            this.showCode = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }
            },
            submitVerifyForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.commitForm();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async commitForm() {
                let params = {
                    phone: this.verifyForm.phone,
                    code: this.verifyForm.verifyCode
                }

                axios({
                        url: `/auth/user/third/bindPhone?type=ycThird&account=${this.checkBindInfo.account}&uniqueid=${this.checkBindInfo.uniqueid}`,
                        method: "post",
                        headers: {
                            "Content-Type": "application/json; charset=UTF-8"
                        },
                        data: params
                    })
                    .then((resData) => {
                        // console.log(resData.data);
                        if (resData.data.code == 200 && resData.data.data.access_token) {
                            // sessionStorage.token = resData.data.data.access_token;
                            this.$store.commit('getToken', resData.data.data.access_token);
                            this.$notify({
                                title: "成功",
                                message: "绑定成功",
                                type: "success"
                            });
                            this.userinfo();
                        } else {
                            this.$notify.error({
                                title: "错误",
                                message: resData.data.data.msg
                            });
                        }
                    }).catch(() => {
                        console.log(resData);
                    })
            },
            // 提交登录
            submitForm(form) {
                if (form.username == "") {
                    this.$notify.error({
                        title: "错误",
                        message: "请填写用户名"
                    });
                    return false;
                }
                if (form.password == "") {
                    this.$notify.error({
                        title: "错误",
                        message: "请填写登陆密码"
                    });
                    return false;
                }
                let params = {
                    username: form.username,
                    password: form.password
                };

                axios({
                        url: `/auth/user/third/bindUser?type=ycThird&account=${this.checkBindInfo.account}&uniqueid=${this.checkBindInfo.uniqueid}`,
                        method: "post",
                        headers: {
                            "Content-Type": "application/json; charset=UTF-8"
                        },
                        data: params
                    })
                    .then((resData) => {
                        console.log(resData);
                        if (resData.data.code == 200 && resData.data.data.access_token) {
                            // sessionStorage.token = resData.data.data.access_token;
                            this.$store.commit('getToken', resData.data.data.access_token);
                            this.$notify({
                                title: "成功",
                                message: "绑定成功",
                                type: "success"
                            });
                            let data = resData.data;
                            this.$router.push({
                                path: '/login',
                                query: {
                                    isThirdLogin: true,
                                    data
                                }
                            })
                            this.userinfo();
                        } else {
                            this.$notify.error({
                                title: "错误",
                                message: resData.data.data.msg
                            });
                        }
                    }).catch(() => {
                        console.log(resData);
                    })

            },
            async userinfo() {
                let params = {
                    // user: "01",
                };
                let resData = await this.$Api.Home.userInfo(params);
                // sessionStorage.userinfo = JSON.stringify(resData.data);
                this.$store.commit('getUserInfo', resData.data);
                this.getroles();
            },
            async getroles() {
                // let params = {};
                let resData = await this.$Api.Home.getroles();
                // sessionStorage.role = resData.data[0].value;
                // sessionStorage.roleId = resData.data[0].id;
                // sessionStorage.rolelist = JSON.stringify(resData.data);
                this.$store.commit('getUserRoleList', resData.data)
                this.$store.commit('getRoleId', resData.data[0].id);
                this.$store.commit('getRole', resData.data[0].value);
                this.$router.push("/home");
            },
            //重置输入框内的数值,
            resetForm(formName) {
                this.$nextTick(() => {
                    if (this.$refs[formName]) {
                        this.$refs[formName].resetFields();
                    }
                });
            },
            checkBind() {
                let params = {
                    code: this.$route.query.code,
                    state: this.$route.query.state,
                    session_state: this.$route.query.session_state,
                    path: `https://${window.location.host}/login/bindAccount`
                }

                axios({
                        url: `/auth/user/third/checkBind?type=ycThird`,
                        method: "post",
                        headers: {
                            "Content-Type": "application/json; charset=UTF-8"
                        },
                        data: params
                    })
                    .then((resData) => {
                        console.log(resData.data.data);
                        if (!resData.data.data.result) {
                            this.checkBindInfo.account = resData.data.data.data.account
                            this.checkBindInfo.uniqueid = resData.data.data.data.uniqueid
                        } else {
                            // sessionStorage.token = resData.data.data.data.access_token;
                            this.$store.commit('getToken', resData.data.data.data.access_token);
                            this.$notify({
                                title: "成功",
                                message: "绑定成功",
                                type: "success"
                            });
                            let data = resData.data;
                            this.$router.push({
                                path: '/login',
                                query: {
                                    isThirdLogin: true,
                                    data
                                }
                            })
                            this.userinfo();
                        }
                    }).catch(() => {
                        console.log(resData);
                    })
            },
            init() {
                sessionStorage.setItem('loginPath', 'third');
                this.checkBind();
            }

        },
        mounted() {
            this.init();
        },
        activated() {},
        created() {},
        deactivated() {
            this.active = 0;
        }
    };
</script>

<style lang="less" scoped>
    .login-bind-account {
        position: relative;
        height: 100vh;
        max-height: 100vh;
        min-width: 1000px;
        background-color: #fff;
        background-image: url("../../assets/images/login/bg.png");
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: 100% 100%;

        .board {
            width: 100%;
            min-width: 900px;
            max-width: 1600px;
            height: 80%;
            min-height: 600px;
            margin: 0 auto;
            padding: 16px 0 0 25px;
            border-radius: 10px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
            background-color: rgba(255, 255, 255, 0.9);
        }

        @media screen and (max-width: 1699px) {
            .board {
                margin: 0 50px;
            }
        }

        @media screen and (max-width: 999px) {
            .board {
                // max-width: 900px;
                min-width: 500px;
            }
        }

        .header {
            li {
                margin-right: 16px;

                &:last-child a {
                    // border-left: 2px solid #1e4c90;
                    padding: 2px 16px;

                    font-size: 16px;
                    font-weight: bold;
                    color: #1e4c90;
                }
            }
        }

        .title {
            margin: 50px 0 30px 70px;
            font-size: 36px;
            color: #2373ec;
            cursor: pointer;
            text-align: center;

            i {
                font-weight: bold;
            }
        }

        .tips {
            font-size: 16px;
            color: #666;
            text-align: center;
            margin-bottom: 52px;
            margin-left: 70px;
        }

        .form-section {
            max-width: 541px;
            margin: 0 auto;

            /deep/ .login-form {
                margin: 0 auto;

                .el-form-item {
                    margin-bottom: 35px;
                }

                .el-form-item__label {
                    line-height: 59px;
                }

                .el-input__inner {
                    border-radius: 2px;
                    width: 100%;
                    max-width: 465px;
                    min-width: 400px;
                    line-height: 59px;
                    height: 59px;
                }

                .el-form-code-item {
                    .el-input {
                        // max-width: 70%;

                        .el-input__inner {
                            width: 100%;
                            min-width: 100%;
                        }
                    }

                    .el-form-item__content {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }

                .validate-code {
                    margin-left: 10px;
                }

                .el-button.getVerfiyCode {
                    margin-left: 10px;
                    width: 120px;
                    height: 58px;
                    border: 1px solid #2473ED;
                    border-radius: 2px;
                    color: #2473ED;
                }

                .summary .el-form-item__content {
                    line-height: 10px;
                    text-align: right;

                    .el-checkbox-group {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .el-link--inner {
                            color: #0496E2;
                        }
                    }
                }

                .el-button.login-btn {
                    border: 1px solid #1E4C90;
                    box-shadow: 1px 8px 15px 0px rgba(29, 76, 144, .35);
                    border-radius: 4px;
                    width: 100%;
                    height: 64px;

                    font-size: 20px;
                    background: linear-gradient(90deg, #2272EC, #3A7BF6);
                    ;
                }

                .switch {
                    font-size: 20px;
                    color: #666;
                    text-align: center;
                }
            }
        }
    }
</style>