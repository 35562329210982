import { render, staticRenderFns } from "./login_bind_account.vue?vue&type=template&id=2e0f83d3&scoped=true"
import script from "./login_bind_account.vue?vue&type=script&lang=js"
export * from "./login_bind_account.vue?vue&type=script&lang=js"
import style0 from "./login_bind_account.vue?vue&type=style&index=0&id=2e0f83d3&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e0f83d3",
  null
  
)

export default component.exports